@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';
@import '../sections/base.scss';

.pb-media-panel {
    margin-top: -1.5em;
    .video-wrapper {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        iframe {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }
    }
    .midcol {
        display: flex;
        align-items: center;
        img {
            display: block;
        }
        .leftcol {
            .modal-trigger {
                &::before {
                    content: "";
                    background-image: url('../../assets/images/icon_play_button.svg');
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 34px;
                    height: 34px;
                    margin-top: -17px;
                    margin-left: -17px;
                    display: block;
                    left: 50%;
                    top: 50%;
                    position: absolute;
                    transition: all 350ms ease-in-out;
                }
                &:hover {
                    cursor: pointer;
                    &::before {
                        transform: scale(1.5);
                    }
                }
            }
        }
        .rightcol {
            h4 {
                font-size: 30px;
                //color: $greymid;
                //font-size: 14px;
            }
            p {
                line-height: 1.4em;
            }
        }
    }
    &__extended {
        .midcol {
            .rightcol {
                h4 {
                    font-family: 'Mont-book';
                    font-size: 17px;
                }
            }
        }
        h3.key-heading {
            margin-bottom: 0.5em !important;
        }
        .extended-copy {
            border-top: 1px solid $atlasred;
            margin-top: 2.25em;
            padding-top: 2em;
            &.copycol {
                p, ol, ul {
                    //font-size: 13px;
                }
                h4 {
                    margin-bottom: 2em;
                    font-size: 17px;
                }
            }
        }
    }
    @include bpr-media-breakpoint(down, md) {
        .midcol {
            .rightcol {
                p {
                    font-size: 16px;
                }
            }
        }
        &.twocol-sixty-forty {
            .midcol {
                flex-direction: column-reverse;
            }
        }
        &__extended {
            .extended-copy {
                margin-top: 0;
            }
        }
    }
}

.pb-key-heading + .pb-media-panel {
    
}